<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div>
		<!-- Sign Up Image And Headings -->
		<div class="sign-up-header" style="background-image: url('./images/SignUpBG.png')">
			<div class="content">
				<h1 class="mb-5">Reset Password</h1>
				<p class="text-lg">Use this form to reset your Quikstone Capital Solutions Portal password.</p>
			</div>
		</div>
		<!-- / Sign Up Image And Headings -->
		
		<!-- Sign Up Form -->
		<a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{paddingTop: 0}">
			<template #title>
				<h6 class="font-semibold text-center">Enter a new password into the fields below.</h6>
			</template>
			<a-form
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
				@submit="handleSubmit"
			>
				<input type="hidden" name="key" :value="key" />
				<a-form-item class="mb-5">
					<a-input
						v-decorator="[
							'password',
							{ rules: [
								{ required: true, min:8, message: 'Please input a password of 8 characters minimum' },
								{ validator: checkComplexity }
							] },
						]"
						type="password"
						placeholder="New Password"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-5">
					<a-input
						v-decorator="[
						'password2',
						{ rules: [
								{ required: true, message: 'Please repeat your password' },
								{ validator: passwordConfirmationRule }
							] },
						]"
						type="password"
						placeholder="Repeat Password"
					>
					</a-input>
				</a-form-item>
				<a-alert v-if="errorState" :message="message" type="warning" show-icon />
				<a-alert v-if="successState" :message="message" type="success" show-icon />
				<a-form-item>
					<a-button type="primary" block html-type="submit" class="login-form-button">
						RESET PASSWORD
					</a-button>
				</a-form-item>
			</a-form>
		</a-card>
		<!-- / Sign Up Form -->

	</div>
</template>

<script>
	import User from '../models/user';

	export default ({
		data() {
			return {
				key: null,
				user: new User('', ''),
				errorState: false,
				successState: false,
				message: ''
			}
		},
		mounted() {
			this.key = this.$route.params.key
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'reset_password' });
		},
		methods: {
			// Handles input validation after submission.
			passwordConfirmationRule(rule, value, callback){
				if(value === this.form.getFieldValue('password')){
					callback();
					return;
				} else {
					callback('Passwords must match');
					return;
				}
			},
			checkComplexity(rule, value, callback) {
				const containsUppercase = /[A-Z]/.test(value)
				const containsLowercase = /[a-z]/.test(value)
				const containsNumber = /[0-9]/.test(value)
				const containsSpecial = /[#?!@$%^&*-]/.test(value)
				
				if( containsUppercase && containsLowercase && containsNumber && containsSpecial ){
					callback();
					return;
				} else {
					callback('Password must contain 1 uppercase, 1 lowercase, 1 number, and 1 special character.');
					return;
				}
			},
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					this.user.password = values.password
					this.user.resetToken = this.key

					if ( !err ) {
						this.$store.dispatch("auth/changePW", this.user).then(
							() => {
								this.successState = true
								this.errorState = false
								this.message = "Your password was successfully reset. You will now be redirected to the Log In page."
								setTimeout(() => {  this.$router.push("/sign-in") }, 5000);
							},
							(error) => {
								this.loading = false;
								this.errorState = true;
								this.message =
									(error.response &&
									error.response.data &&
									error.response.data.message) ||
									error.message ||
									error.toString();
							}
						);
					} else {
						this.loading = false
						this.errorState = true
						this.message = err
					}
				});
			},
		},
	})

</script>

<style lang="scss">
</style>